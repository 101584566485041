import React from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async';

const Services= () => {
  return (
    <>
    <HelmetProvider>
    <div>
      <Helmet>
  <meta charSet="utf-8" />
  <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
  <meta name="viewport" content="width=device-width" />
  <title>Services</title>
  <link rel="canonical" href="https://enigmAnce.com/services" />
  <link rel="alternate" hrefLang="en-KE" href="https://enigmAnce.com/services" />
  <link rel="alternate" hrefLang="x-default" href="https://enigmAnce.com/services" />
  <meta
     name="keywords"
     content="Web dev, web design, graphics, react js, next, html, css, boostrap, php, app development"

  />
  <meta
     name="description" 
     content="We specialize in providing our clients with a variety 
     of different services that we are able to offer due to the vast amount of
      expertise we have under one roof. It is our goal that after you work 
      with us you will be satisfied and have a better understanding of what it
       takes to reach your target markets."
  
  />
  <meta name="robots" content="index, follow" />
  <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
  <meta name="language" content="English" />
  <link rel="shortcut icon" href="images/favicon.ico" type="image/x-icon" />

    
  <meta property="fb:pages" content={105481065704779} />
  <meta property="fb:app_id" content={838406994270743} />
  <meta property="og:url" content="https://enigmAnce.com/services" />
  <meta property="og:type" content="website" />
  <meta
    property="og:title"
    content="Services"
  />
  <meta
    property="og:description"
    content="We specialize in providing our clients with a variety 
     of different services that we are able to offer due to the vast amount of
      expertise we have under one roof. It is our goal that after you work 
      with us you will be satisfied and have a better understanding of what it
       takes to reach your target markets."
  />
  <meta
    property="og:image"
    content="https://enigmAnce.com/images/upload/slider_05.webp"
  />
  <meta name="twitter:card" content="summary_large_image" />
  <meta property="twitter:site" content="@enigmAncestudio" />
  <meta property="twitter:url" content="https://enigmAnce.com/services" />
  <meta
    name="twitter:title"
    content="Services"
  />
  <meta
    name="twitter:description"
    content="We specialize in providing our clients with a variety 
     of different services that we are able to offer due to the vast amount of
      expertise we have under one roof. It is our goal that after you work 
      with us you will be satisfied and have a better understanding of what it
       takes to reach your target markets."
  />
  <meta
    name="twitter:image"
    content="https://enigmAnce.com/images/upload/slider_05.webp"
  />
 </Helmet>  
 </div>
 </HelmetProvider>


  <section className="innerpage_top_section">
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <h3>Services</h3>
        </div>
      </div>
    </div>
  </section>
  {/* Web */}{" "}
  <section className="section">
    <div className="container">
      <div className="row">
        <div className="col-md-4 col-sm-6">
          <div className="custom-module">
            <h1>Web Development</h1>
            <br />
            <p>
              From inception to research, design and deployment enigmAnce will
              be carefully co-ordinating with you for a more engaging and
              vibrant website. You're in good hands with our team of young and
              passionate developers who'll provide you with an unforgettable
              experience. Our customized design process ensures you receive a
              high performance and SEO optimized website, expertly crafted for
              your exact business.
              <br />
              Do not hesitate to get in touch with us for all your Web Designing
              needs!
            </p>
          </div>
          {/* end custom-module */}
        </div>
        {/* end col */}
        <div className="col-md-4 col-sm-6">
          <div className="custom-module">
            <div className="blog-box">
              <br />
              <div className="post-media">
                <img
                  src="images/illustration/web-developer.svg"
                  alt="Web Design"
                  className="img-responsive"
                />
              </div>
              {/* end media */}
              <div className="blog-desc">
                <h2>Dev &amp; Launch</h2>
                <ul className="check">
                  <li>Engaging &amp; Quality Content</li>
                  <li>SEO Optimized &amp; Perfomance</li>
                  <li>Powerful &amp; Creative Web Design Layout.</li>
                  <li>Search Engine Indexing</li>
                  <li>Dynamic &amp; Static Design</li>
                  <li>Responsive Theme</li>
                  <div className="btn-wrapper">
                    <a href="contact" className="btn btn-primary">
                      Request Service
                    </a>
                  </div>
                </ul>
              </div>
              {/* end blog-desc */}
            </div>
          </div>
          {/* end custom-module */}
        </div>
        {/* end col */}
        <div className="col-md-4 col-sm-6">
          <div className="custom-module">
            <div className="blog-box">
              <br />
              <div className="post-media">
                <img
                  src="images/illustration/web-design.svg"
                  alt="Web Development"
                  className="img-responsive"
                />
              </div>
              {/* end media */}
              <div className="blog-desc">
                <h2>Redesigning</h2>
                <ul className="check">
                  <li>A fresh new look &amp; feel</li>
                  <li>Site Audit &amp; Issue Fix</li>
                  <li>Re-indexing &amp; Rank Checking.</li>
                  <li>Bug Fix</li>
                  <li>Site Metrics &amp; Perfomance</li>
                  <li>Site Migration</li>
                  <div className="btn-wrapper">
                    <a href="contact" className="btn btn-primary">
                      Request Service
                    </a>
                  </div>
                </ul>
              </div>
              {/* end blog-desc */}
            </div>
          </div>
          {/* end custom-module */}
        </div>
        {/* end col */}
      </div>
      {/* end row */}
    </div>
    {/* end container */}
  </section>
  {/* Graphics */}
  <section className="section">
    <div className="container">
      <div className="row">
        <div className="col-md-4 col-sm-6">
          <div className="custom-module">
            <h3>Graphic Design</h3>
            <br />
            <p>
              There is an old Latin proverb that says "a picture is worth a
              thousand words" and that has never been truer than in the
              information-rich world of marketing and communications. Well, look
              no further because we are your creative design partners for all
              things graphic design.
              <br />
            </p>
          </div>
          {/* end custom-module */}
        </div>
        {/* end col */}
        <div className="col-md-4 col-sm-6">
          <div className="custom-module">
            <div className="blog-box">
              <br />
              <div className="post-media">
                <img
                  src="images/illustration/graphics.svg"
                  alt="Graphics"
                  className="img-responsive"
                />
              </div>
              {/* end media */}
              <div className="blog-desc">
                <h2>2D</h2>
                <ul className="check">
                  <li>Brand identity</li>
                  <li>Content Creation</li>
                  <li>Motion Graphics</li>
                  <li>Art &amp; illustrations</li>
                  <li>Publications</li>
                  <li>Video Editing</li>
                  <div className="btn-wrapper">
                    <a href="contact" className="btn btn-primary">
                      Request Service
                    </a>
                  </div>
                </ul>
              </div>
              {/* end blog-desc */}
            </div>
          </div>
          {/* end custom-module */}
        </div>
        {/* end col */}
        <div className="col-md-4 col-sm-6">
          <div className="custom-module">
            <div className="blog-box">
              <br />
              <div className="post-media">
                <img
                  src="images/illustration/video.svg"
                  alt="Graphic Design"
                  className="img-responsive"
                />
              </div>
              {/* end media */}
              <div className="blog-desc">
                <h2>3D</h2>
                <ul className="check">
                  <li>Product Design</li>
                  <li>Computer Generated Imagery</li>
                  <li>Concept art creation &amp; Visualization</li>
                  <li>3D Product Animation</li>
                  <li>Marketing Videos</li>
                  <li>Virtual Models</li>
                  <div className="btn-wrapper">
                    <a href="contact" className="btn btn-primary">
                      Request Service
                    </a>
                  </div>
                </ul>
              </div>
              {/* end blog-desc */}
            </div>
          </div>
          {/* end custom-module */}
        </div>
        {/* end col */}
      </div>
      {/* end row */}
    </div>
    {/* end container */}
  </section>
  {/* Marketing */}
  <section className="section">
    <div className="container">
      <div className="row">
        <div className="col-md-4 col-sm-6">
          <div className="custom-module">
            <h3>Digital Marketing</h3>
            <br />
            <p>
              Marketing in the Digital Age has become a crucial part of business
              as it continues to shift, grow, change and evolve to meet the
              needs of customers and current marketing trends. With a multitude
              of tools available to marketers today, you can count on enigmAnce
              to provide you with the latest tools and strategy for your growth.
            </p>
          </div>
          {/* end custom-module */}
        </div>
        {/* end col */}
        <div className="col-md-4 col-sm-6">
          <div className="custom-module">
            <div className="blog-box">
              <br />
              <div className="post-media">
                <img
                  src="images/illustration/social-dilemma.svg"
                  alt="Online Marketing"
                  className="img-responsive"
                />
              </div>
              {/* end media */}
              <div className="blog-desc">
                <h2>Social Media</h2>
                <ul className="check">
                  <li>Sales and lead generation</li>
                  <li>Website traffic growth</li>
                  <li>competitors &amp; trends monitoring</li>
                  <li>Audience growth &amp; Retention</li>
                  <li>Brand Building &amp; Exposure</li>
                  <div className="btn-wrapper">
                    <a href="contact" className="btn btn-primary">
                      Request Service
                    </a>
                  </div>
                </ul>
              </div>
              {/* end blog-desc */}
            </div>
          </div>
          {/* end custom-module */}
        </div>
        {/* end col */}
        <div className="col-md-4 col-sm-6">
          <div className="custom-module">
            <div className="blog-box">
              <br />
              <div className="post-media">
                <img
                  src="images/illustration/marketing.svg"
                  alt="Social Media Marketing"
                  className="img-responsive"
                />
              </div>
              {/* end media */}
              <div className="blog-desc">
                <h2>Content Marketing</h2>
                <ul className="check">
                  <li>Social Media posts</li>
                  <li>Brand Promotion</li>
                  <li>Distribution.</li>
                  <li>Audience Refining</li>
                  <li>Value Propositioning</li>
                  <div className="btn-wrapper">
                    <a href="contact" className="btn btn-primary">
                      Request Service
                    </a>
                  </div>
                </ul>
              </div>
              {/* end blog-desc */}
            </div>
          </div>
          {/* end custom-module */}
        </div>
        {/* end col */}
      </div>
      {/* end row */}
    </div>
    {/* end container */}
  </section>
</>


  )
}

export default Services