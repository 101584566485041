import React from 'react'

function Terms() {
  return (
    <>
    <section className="section">
  <div className="container">
    <div className="row">
      <div className="col-md-12">
        <div className="custom-module">
          <h1>
            <strong>Website Terms and Conditions of Use</strong>
          </h1>
          <strong>
            <h2>1. Terms</h2>
          </strong>
          <p className="blogArticle">
            By accessing this Website, accessible from https://enigmance.com,
            you are agreeing to be bound by these Website Terms and Conditions
            of Use and agree that you are responsible for the agreement with any
            applicable local laws. If you disagree with any of these terms, you
            are prohibited from accessing this site. The materials contained in
            this Website are protected by copyright and trade mark law.
          </p>
          <strong>
            <h2>2. Use License</h2>
          </strong>
          <p className="blogArticle">
            Permission is granted to temporarily download one copy of the
            materials on enigmance's Website for personal, non-commercial
            transitory viewing only. This is the grant of a license, not a
            transfer of title, and under this license you may not:
          </p>
          <p className="blogArticle"></p>
          <ul className="bloglist">
            <li>modify or copy the materials;</li>
            <li>
              use the materials for any commercial purpose or for any public
              display;
            </li>
            <li>
              attempt to reverse engineer any software contained on enigmance's
              Website;
            </li>
            <li>
              remove any copyright or other proprietary notations from the
              materials; or
            </li>
            <li>
              transferring the materials to another person or "mirror" the
              materials on any other server.
            </li>
          </ul>
          <p />
          <p className="blogArticle">
            This will let enigmance to terminate upon violations of any of these
            restrictions. Upon termination, your viewing right will also be
            terminated and you should destroy any downloaded materials in your
            possession whether it is printed or electronic format.
          </p>
          <strong>
            <h2>3. Disclaimer</h2>
          </strong>
          <p className="blogArticle">
            All the materials on enigmance’s Website are provided "as is".
            enigmance makes no warranties, may it be expressed or implied,
            therefore negates all other warranties. Furthermore, enigmance does
            not make any representations concerning the accuracy or reliability
            of the use of the materials on its Website or otherwise relating to
            such materials or any sites linked to this Website.
          </p>
          <h2>
            <strong>4. Limitations</strong>
          </h2>
          <p className="blogArticle">
            enigmance or its suppliers will not be hold accountable for any
            damages that will arise with the use or inability to use the
            materials on enigmance’s Website, even if enigmance or an authorize
            representative of this Website has been notified, orally or written,
            of the possibility of such damage. Some jurisdiction does not allow
            limitations on implied warranties or limitations of liability for
            incidental damages, these limitations may not apply to you.
          </p>
          <h2>
            <strong>5. Revisions and Errata</strong>
          </h2>
          <p className="blogArticle">
            The materials appearing on enigmance’s Website may include
            technical, typographical, or photographic errors. enigmance will not
            promise that any of the materials in this Website are accurate,
            complete, or current. enigmance may change the materials contained
            on its Website at any time without notice. enigmance does not make
            any commitment to update the materials.
          </p>
          <h2>
            <strong>6. Links</strong>
          </h2>
          <p className="blogArticle">
            enigmance has not reviewed all of the sites linked to its Website
            and is not responsible for the contents of any such linked site. The
            presence of any link does not imply endorsement by enigmance of the
            site. The use of any linked website is at the user’s own risk.
          </p>
          <h2>
            <strong>7. Site Terms of Use Modifications</strong>
          </h2>
          <p className="blogArticle">
            enigmance may revise these Terms of Use for its Website at any time
            without prior notice. By using this Website, you are agreeing to be
            bound by the current version of these Terms and Conditions of Use.
          </p>
          <h2>
            <strong>8. Your Privacy</strong>
          </h2>
          <p className="blogArticle">
            Please read our{" "}
            <a href="https://enigmance.com/Privacy Policy">Privacy Policy.</a>
          </p>
          <h2>
            <strong>9. Governing Law</strong>
          </h2>
          <p className="blogArticle">
            Any claim related to enigmance's Website shall be governed by the
            laws of Kenya without regards to its conflict of law provisions.
          </p>
        </div>
        {/* end custom-module */}
      </div>
      {/* end col */}
    </div>
    {/* end row */}
  </div>
  {/* end container */}
</section>

    
    </>
  )
}

export default Terms