import React from 'react'
import Weather from './Weather'
function Footer() {
  return (
   <>
 
      <div>
        <footer className="section footer light-footer">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center">
                <div className="contact-details">
                  <img src="images/logo.svg" width="300px" heigh="144px" alt="logo" />
                  <p>Phone:  +254 722 951 731</p>
                  <ul className="list-inline social-links">
                    <li><a href="https://www.facebook.com/enigmAnce" aria-label="Facebook" target="_blank" rel="noreferrer"><i className="fa fa-facebook" /></a></li>
                    <li><a href="https://twitter.com/enigmAncestudio" aria-label="Twitter" target="_blank" rel="noreferrer"><i className="fa fa-twitter" /></a></li>
                    <li><a href="https://instagram.com/enigmAnce" aria-label="Instagram" target="_blank" rel="noreferrer"><i className="fa fa-instagram" /></a></li>
                  </ul>
                  <p><strong>Copyrights © 2023 All Rights Reserved</strong></p>
                </div>{/* end details */}
              </div>{/* end light-footer */}
            </div>{/* end row */}    
          </div>{/* end container */}
        </footer>{/* end footer */}
        <section className="section db">
          <div className="container">
            <div className="section-title">
              <div className="container">
                <div className="col-md-4 col-sm-4">
                  <div className="custom-module">
                    <h4>About <img src="images/TM-01.svg" width="110px" height="50px" alt="km" /></h4>
                    <p className="enigmance">enigmAnce is a full service digital marketing, graphic designing, web and app developing firm. Founded by A. S. Hassan.</p>
                  </div>{/* end custom-module */}
                  <Weather />
                </div>{/* end col */}
                
    
                <div className="col-md-4 col-sm-4">
                  <div className="custom-module">
                    <h4>Site Links</h4>
                    <ul className="Contacts">
                      <li className="Links"><a href="/" aria-label="Home"><img src="images/Footer/Home.svg" alt="Bl" width="38px" height="38px" />
                          Home</a></li>
                      <li className="Links"><a href="Services" aria-label="Services"><img src="images/Footer/services.svg" alt="Bl" width="38px" height="38px" />
                          Services</a></li>
                      <li className="Links"><a href="Blog" aria-label="Blog"><img src="images/Footer/blog.svg" alt="Bl" width="38px" height="38px" />
                          Blog</a></li>
                      <li className="Links"><a href="Contact" aria-label="Contact"><img src="images/Footer/contact.svg" alt="CC" width="38px" height="38px" />
                          Contacts</a></li>
                      <li className="Links"><a href="About" aria-label="About"><img src="images/Footer/about.svg" alt="Ab" width="38px" height="38px" />
                          About</a></li>
                      <li className="Links"><a href="Privacy Policy" aria-label="Privacy Policy"><img src="images/Footer/Privacy.svg" alt="PP" width="38px" height="38px" />
                          Privacy Policy</a></li>
                      <li className="Links"><a href="Terms" aria-label="Terms of Service"><img src="images/Footer/Terms.svg" alt="TS" width="38px" height="38px" />
                          Terms </a></li>
                    </ul>
                  </div>{/* end custom-module */}
                </div>{/* end col */}
                <div className="col-md-4 col-sm-4">
                  <div className="custom-module">
                    <h4>Reach Out</h4>
                    <span className="fa-stack fa-lg">
                      <i className="fa fa-circle-thin fa-stack-2x" />
                      <i className="fa fa-phone fa-stack-1x" />
                    </span>
                    +254 722 951 731<br />
                    <span className="fa-stack fa-lg">
                      <i className="fa fa-circle-thin fa-stack-2x" />
                      <i className="fa fa-envelope fa-stack-1x " />
                    </span>
                    <img src="images/Footer/mail-01.svg" width="100px" height="58px" alt="mail" /><br />
                    <span className="fa-stack fa-lg">
                      <i className="fa fa-circle-thin fa-stack-2x" />
                      <i className="fa fa-facebook fa-stack-1x" />
                    </span>
                    @enigmance <br />
                    <span className="fa-stack fa-lg">
                      <i className="fa fa-circle-thin fa-stack-2x" />
                      <i className="fa fa-twitter fa-stack-1x" />
                    </span>
                    @enigmancestudio <br />
                    <span className="fa-stack fa-lg">
                      <i className="fa fa-circle-thin fa-stack-2x" />
                      <i className="fa fa-instagram fa-stack-1x" />
                    </span>
                    @enigmance
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

   
   
   </>
  )
}

export default Footer