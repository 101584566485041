import React from "react";

export default function ErrorPage() {
    return (
        <>
    
          <div>
            
            
          <section className="section lb">
              <div className="container">
                <div className="section-title text-center">
                    <h1>404</h1>
                    <br />
                    <h1>Page Not Found</h1>
                    <br />
                    <div className="btn-wrapper">
                  <a href="/" className="btn btn-primary">Go Back</a>
                </div>

                
                </div>{/* end title */}
              </div>{/* end container */}
            </section>
            
          </div>
    
        </>
        )
    }

