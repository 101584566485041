// import necessary modules for form submission
import React from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

class ContactForm extends React.Component {
  constructor() {
    super();
    this.state = {
      fields: {
        name: "",
        email: "",
        phone: "",
        subject: "",
        countryName: "",
      },

      errors: {},
    };

    this.handleChange = this.handleChange.bind(this);
    this.submitQueries = this.submitQueries.bind(this);
  }

  handleChange(e) {
    let fields = this.state.fields;
    this.setState({
      fields: {
        ...fields,
        [e.target.name]: e.target.value,
      },
    });
  }

  // submit form using toast promise and resolve or reject
  submitQueries(e) {
    try {
      e.preventDefault();
      if (this.validateForm()) {
        // use setTimeout to clear form after submission
        toast.promise(
          axios.post(`${ process.env.REACT_APP_ORIGIN}/api/contactform`, {
            name: this.state.fields.name,
            email: this.state.fields.email,
            phone: this.state.fields.phone,
            subject: this.state.fields.subject,
            message: this.state.fields.message,
          }),

          // set time interval for pending toast
          {
            pending: {
              render: () => <div>Submitting...</div>,
            },
            success: {
              render: () => <div>Thank you for your submission!</div>,
            },
            error: {
              render: () => <div>Something went wrong!</div>,
            },

            // no internet connection
          }
        );
        setTimeout(() => {
          this.setState({
            fields: {
              name: "",
              email: "",
              phone: "",
              subject: "",
              message: "",
            },
          });
        }, 3000);
      }
    } catch (error) {
      console.log(error);
    }
  }

  validateForm() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (!fields["name"]) {
      formIsValid = false;
      toast.error("Please enter your name.");
    }

    if (!fields["email"]) {
      formIsValid = false;
      toast.error("Please enter your email-ID.");
    }

    if (typeof fields["email"] !== "undefined") {
      //regular expression for email validation
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(fields["email"])) {
        formIsValid = false;
        toast.error("Please enter valid email-ID.");
      }
    }

    if (!fields["phone"]) {
      formIsValid = false;
      toast.error("Please enter your phone number.");
    }

    if (typeof fields["phone"] !== "undefined") {
      if (!fields["phone"].match(/^[0-9]{10}$/)) {
        formIsValid = false;
        toast.error("Please enter valid phone number.");
      }
    }

    if (!fields["subject"]) {
      formIsValid = false;
      toast.error("Please enter your subject.");
    }

    if (!fields["message"]) {
      formIsValid = false;
      toast.error("Please enter your message.");
    }

    this.setState({
      errors: errors,
    });
    return formIsValid;
  }

  render() {
    return (
      <div id="main-registration-container">
        <div id="form">
          <form
            method="post"
            className="contact-form "
            name="userRegistrationForm"
            onSubmit={this.submitQueries}
          >
            <input
              type="text"
              name="name"
              className="input md:w-1/2"
              value={this.state.fields.name}
              placeholder="Your Name"
              onChange={this.handleChange}
            />

            <div className="errorMsg">{this.state.errors.name}</div>

            <input
              type="email"
              className="input md:w-1/2"
              placeholder="Your Email"
              name="email"
              value={this.state.fields.email}
              onChange={this.handleChange}
            />
            <div className="errorMsg">{this.state.errors.email}</div>

            <input
              type="phone"
              className="input md:w-1/2"
              name="phone"
              placeholder="Your Phone"
              value={this.state.fields.phone}
              onChange={this.handleChange}
            />

            <div className="errorMsg">{this.state.errors.phone}</div>

            <input
              type="subject"
              className="input md:w-1/2"
              name="subject"
              placeholder="Your Subject"
              value={this.state.fields.subject}
              onChange={this.handleChange}
            />
            <div className="errorMsg">{this.state.errors.subject}</div>

            <textarea
              className="input md:w-1/2"
              name="message"
              placeholder="Your Message"
              value={this.state.fields.message}
              onChange={this.handleChange}
            />
            <div className="errorMsg">{this.state.errors.message}</div>

            <ToastContainer
              position="top-center"
              autoClose={3000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
            />
            <input
              type="submit"
              className="mb-6"
              onClick={this.submitQueries}
              value="Submit"
            />
          </form>
        </div>
      </div>
    );
  }
}

export default ContactForm;
