import React from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'

const Knowledge = ()=> {
  return (
    <>
    <HelmetProvider>
    <div>
      <Helmet>
  <meta charSet="utf-8" />
  <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
  <meta name="viewport" content="width=device-width" />
  <title>Knowledge Center</title>
  <link rel="canonical" href="https://enigmAnce.com/knowledge" />
  <link rel="alternate" hrefLang="en-KE" href="https://enigmAnce.com/knowledge" />
  <link rel="alternate" hrefLang="x-default" href="https://enigmAnce.com/knowledge" />
  <meta
   name="keywords" 
   content="Social media in marketing, 
   advertising agencies, brand in marketing, digital marketing agencies in Kenya, 
   digital marketing companies in Kenya, online marketing in Kenya, marketing services,
   marketing agencies, online marketing platforms in Kenya "
    
  />
  <meta
    name="description"
    contencontent="The goal of this section is to help you stay up-to-date with the latest trends 
    from digital marketing, web development and graphic design"
  />
  <meta name="robots" content="index, follow" />
  <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
  <meta name="language" content="English" />
  <link rel="shortcut icon" href="images/favicon.ico" type="image/x-icon" />

 </Helmet>  
 </div>
 </HelmetProvider>

   

   <section className="section lb">
        <div className="container">
          <div className="section-title text-center">
            <h1>Knowledge Center</h1>
            <p className="blogP">At Knowledge Center, we help you stay up-to-date with the latest mania so that you best leverage them to your advantage. You can be sure that our content is both informative and guaranteed to be educational to all.</p>
          </div>{/* end title */}
          <div className="row">
            <div className="col-lg-3 col-md-4 col-sm-12">
              <h3><strong>Categories</strong></h3><br /><br />
              <ul className="categories">
                <li><a href="Marketing">Online Marketing</a></li>
                <li><a href="Graphics">Graphic Design</a></li>
                <li><a href="Web Design and Development">Web Design</a></li>
              </ul>
              <h3>Recent Post</h3>
              <div className="readmore">
                <p className="bp2">13th Nov 2022</p>
                <br />
                <span className="SideBarLinks">
                  <a href="Social%20Media%20Marketing%20What%20It%20Is,%20How%20It%20Works,%20Pros%20And%20Cons" target="_blank">Social Media Marketing: What it is, How it works, Pros &amp; Cons</a>
                </span>
              </div>
              <p className="bp__p">With over 80% of consumers reporting that social media especially influencer content significantly impacts buying decisions, marketers across industries are driving the evolution of social media marketing (SMM) from a stand-alone tool to a multipronged source of marketing intelligence on an increasingly important and growing audience.</p>
              <div className="readmore">
                <p className="bp2">18th Nov 2022</p>
                <span className="SideBarLinks">
                  <a href="Should%20You%20Choose%20Public%20Relations%20Or%20Content%20Marketing%20For%20Your%20Business" target="_blank">Dillema of Choice: Public Relation Vs Content Marketing</a>
                </span>
              </div>
              <p className="bp__p">The world is evolving with every passing second. The same methods brands used to grow their presence a few years back may not work. In the past, brands have relied heavily on public relations to create a strong foundation for their businesses. However, with the advent of social media, brands have had to move to digital strategies to create a positive image and boost brand awareness</p>  
              <div className="readmore">
                <p className="bp2">15th Nov 2022</p>
                <span className="SideBarLinks">
                  <a href="2023%20Digital%20Marketing%20Trends%20to%20Take%20Advantage%20of%20Now" targe="_blank">2023 Digital Marketing Trends to Take Advantage of</a>
                </span>
              </div>
              <p className="bp__p">Many businesses are already mapping out their digital marketing plans for 2023 and beyond. Smart businesses are looking for the latest trends to incorporate to maximize their results and impact. Here are some of the biggest trends impacting digital marketing that can help grow your success.</p>  
              <div className="readmore">
                <p className="bp2">13th Nov</p>
                <span className="SideBarLinks">
                  <a href="Why%20Your%20Business%20Should%20Not%20Be%20On%20Every%20Social%20Media%20Platform" target="_blank">Why Your Business Should Not Be On Every Social Media Platform</a>
                </span>
              </div>
              <p className="bp__p">Social media is a great tool to ensure you reach a wider audience and maximize conversions. Most businesses think activating every social network will lead to business growth and ultimately run profitable businesses.</p>
              <div className="readmore">
                <h4>Quick Links</h4>
                <button className="btn--e-transparent-brand-b-2"><a href="https://enigmance.com/Social%20Media%20Marketing%20What%20It%20Is,%20How%20It%20Works,%20Pros%20And%20Cons">Social Media</a></button>
                <button className="btn--e-transparent-brand-b-2"><a href="https://enigmance.com/Social%20Media%20Marketing%20What%20It%20Is,%20How%20It%20Works,%20Pros%20And%20Cons">Online Marketing</a></button>
                <button className="btn--e-transparent-brand-b-2"><a href="https://enigmance.com/Why%20Your%20Business%20Should%20Not%20Be%20On%20Every%20Social%20Media%20Platform">Digital Marketing</a></button><button className="btn--e-transparent-brand-b-2"><a href="https://enigmance.com/2023%20Digital%20Marketing%20Trends%20to%20Take%20Advantage%20of%20Now">Brand Marketing</a></button>
                <br />
                <button className="btn--e-transparent-brand-b-2"><a href="https://enigmance.com/Should%20You%20Choose%20Public%20Relations%20Or%20Content%20Marketing%20For%20Your%20Business">Content Marketing</a></button>
                <button className="btn--e-transparent-brand-b-2"><a href="https://enigmance.com/Should%20You%20Choose%20Public%20Relations%20Or%20Content%20Marketing%20For%20Your%20Business">Brand Marketing</a></button>
              </div>
            </div>{/* end col */}
            <div className="col-lg-9 col-md-8 col-sm-12">
              <div className="knowledgeBox">
                <div className="post-media">
                  <a href="https://enigmance.com/Knowledge/Graphics"><img src="images/upload/graphics_knowledge.webp" width="1366px" height="766px" alt="Graphic Designing Agency" className="img-responsive" /></a>
                </div>{/* end media */}
                {/* end post-meta */}
                <h2>Everything Graphic Design</h2>
                <div className="post-meta">
                  <button className="btn--e-transparent-brand-b-2"><a href="https://enigmance.com/Knowledge/Graphics">Graphic</a></button>
                  <button className="btn--e-transparent-brand-b-2"><a href="https://enigmance.com/Knowledge/Graphics">Graphics Design</a></button>
                </div> 
                <p className="bp__p">On this section we provide  you with some of the best tips and tricks in the graphic design realm. From do's and dont's to general graphic designing principles, you won't be disappointed. There are many things that go into a successful design, and we are here to share our knowledge with you. So what are you waiting for? Get started.</p>
                <div className="readmore">
                  <span className="bp__read-more">
                    <a href="https://enigmance.com/Knowledge/Graphics">CONTINUE</a>
                  </span>
                </div>
              </div>
              <div className="knowledgeBox">
                <div className="post-media">
                  <a href="https://enigmance.com/Knowledge/Web%20Design%20and%20Development"><img src="images/upload/web_knowledge.webp" width="1366px" height="766px" alt="Web Designing and Development" className="img-responsive" /></a>
                </div>{/* end media */}
                <h2>Everything Web Development &amp; Design</h2>
                <div className="post-meta">
                  <button className="btn--e-transparent-brand-b-2"><a href="https://enigmance.com/Knowledge/Web%20Design%20and%20Development">Web Development</a></button>
                  <button className="btn--e-transparent-brand-b-2"><a href="https://enigmance.com/Knowledge/Web%20Design%20and%20Development">Web Design</a></button>
                  <button className="btn--e-transparent-brand-b-2"><a href="https://enigmance.com/Knowledge/Web%20Design%20and%20Development">Online Marketing</a></button>
                </div> 
                <p className="bp__p">On this section we regularly post articles on topics related to web design and development. Topics that might be covered include HTML, CSS, Bootstrap, marketing strategies and trends.</p>
                <div className="readmore">
                  <span className="bp__read-more">
                    <a href="https://enigmance.com/Knowledge/Web%20Design%20and%20Development">CONTINUE</a>
                  </span>
                </div>
              </div>
              <div className="knowledgeBox">
                <div className="post-media">
                  <a href="https://enigmance.com/Knowledge/Marketing"><img src="images/upload/digital-marketing-knowledge.webp" width="1366px" height="766px" alt="Digital Marketing, Online Marketing, Social Media Marketing Agency" className="img-responsive" /></a>
                </div>{/* end media */}
                <h2>Everything Online Marketing</h2>
                <div className="post-meta">
                  <button className="btn--e-transparent-brand-b-2"><a href="https://enigmance.com/Knowledge/Web%20Design%20and%20Development">Social Media Marketing</a></button>
                  <button className="btn--e-transparent-brand-b-2"><a href="https://enigmance.com/Knowledge/Web%20Design%20and%20Development">Online Ads</a></button>
                </div> 
                <p className="bp__p">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.</p>
                <div className="readmore">
                  <span className="bp__read-more">
                    <a href="https://enigmance.com/Knowledge/Marketing">CONTINUE</a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

   </>
  )
}

export default Knowledge