import {  useRoutes } from 'react-router-dom';
import About from './components/About';
import Contact from './components/Contact';
import Blog from './components/Blog';
import Services from './components/Services';
import Home from './components/Home';
import Blog2023 from './components/Blog2023';
import BlogShould from './components/BlogShould';
import BlogWhy from './components/BlogWhy';
import BlogSocial from './components/BlogSocial';
import Privacy from './components/Privacy';
import Knowledge from './components/Knowledge';
import Terms from './components/Terms';
import ThreeScene from './components/Portofolio';
import ErrorPage from './components/ErrorPage';

export default function Router() {
    const routes = [
        { path: '/', element: <Home /> },
        { path: '/About', element: <About /> },
        { path: '/Contact', element: <Contact /> },
        { path: '/Blog', element: <Blog /> },
        { path: '/Services', element: <Services /> },
        { path: '/2023 Digital Marketing Trends to Take Advantage of Now', element: <Blog2023 /> },
        { path: '/Should You Choose Public Relations Or Content Marketing For Your Business', element: <BlogShould /> },
        { path: '/Why Your Business Should Not Be On Every Social Media Platform', element: <BlogWhy /> },
        { path: '/Social Media Marketing What It Is, How It Works, Pros And Cons', element: <BlogSocial /> },
        { path: '/knowledge', element: <Knowledge /> },
        { path: '/portofolio', element: <ThreeScene /> },
        { path: '/privacy policy', element: <Privacy /> },
        { path: '/terms', element: <Terms /> },
        { path: '*', element: <ErrorPage /> },
    ];
    
    return useRoutes(routes);
    }

