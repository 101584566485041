import React from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async';

const About = () => {
  return (
    <>
    <HelmetProvider>
    <div>
      <Helmet>
  <meta charSet="utf-8" />
  <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
  <meta name="viewport" content="width=device-width" />
  <title>About</title>
  <link rel="canonical" href="https://enigmAnce.com/about" />
  <link rel="alternate" hrefLang="en-KE" href="https://enigmAnce.com/about" />
  <link rel="alternate" hrefLang="x-default" href="https://enigmAnce.com/about" />
  <meta
    name="keywords"
    content="digital marketing, social media, network marketing, marketing strategy, email marketing, content marketing, market segmentation, marketing management, marketing services, graphic, design, brand management, web design, business "
  />
  <meta
    name="description"
    content="A digital marketing company that specializes in helping you 
    come up with a marketing strategy, from web development to social media management"


  />
  <meta name="robots" content="index, follow" />
  <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
  <meta name="language" content="English" />
  <link rel="shortcut icon" href="images/favicon.ico" type="image/x-icon" />

 </Helmet>  
 </div>
 </HelmetProvider>
 
      <div>
           <section className="innerpage_top_section">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h3>About us</h3>
              </div>
            </div>
          </div>
        </section>
        <section className="section">
          <div className="container">
            <div className="row">
              <div className="col-md-4 col-sm-6">
                <div className="custom-module">
                  <h1>About enigmAnce</h1>
                  <p>A full service digital marketing and website development firm, with designers for all type of businesses. We have the tools and the expertise to create product / services that will help your business succeed online, from powerful and creative web layout designs that are SEO-optimized to brand campaigns that have you discovered by new customers. All our services are heavily oriented towards Search Engine optimization and focus on customer-centricity. With a SEO friendly website and a professionally branded social media, we'll help you unlock new avenues of success with exponential growth in online traffic. </p>
                </div>{/* end custom-module */}
              </div>{/* end col */}
              <div className="col-md-4 col-sm-6">
                <div className="custom-module"><br />
                  <h4>Our Values</h4>
                  <ul className="list-style-2">
                    <li><i className="fa fa-star-o" /> Customer-Centricity</li>
                    <li><i className="fa fa-star-o" /> Trust &amp; Fiscal Wellbeing</li>
                    <li><i className="fa fa-star-o" /> Respect &amp; Diversity</li>
                    <li><i className="fa fa-star-o" /> Service &amp; Productivity</li>
                    <li><i className="fa fa-star-o" /> Innovation &amp; Creativity</li>
                    <li><i className="fa fa-star-o" /> Pragmatism &amp; Dedication</li>
                    <li><i className="fa fa-star-o" /> Passion &amp; Conviction</li>
                    <li><i className="fa fa-star-o" /> Punctuality  &amp; Effectiveness</li>
                  </ul>
                </div>{/* end custom-module */}
              </div>{/* end col */}
              <div className="col-md-4 col-sm-6">
                <div className="custom-module">
                  <div className="custom-module">
                    <img src="images/illustration/friendship.svg" alt="Digital Marketing, Web & App Designing, Graphic Designing" className="img-responsive" />
                  </div>
                  {/* end skills */}
                </div>{/* end custom-module */}
              </div>{/* end col */}
            </div>{/* end row */}
          </div>{/* end container */}
        </section>
        <section className="section lb">
          <div className="container">
            <div className="row">
              <div className="col-md-4 hidden-sm hidden-xs">
                <div className="custom-module">
                  <img src="images/illustration/earn-rewards.svg" alt="Digital Marketing, Web & App Designing, Graphic Designing" className="img-responsive" />
                </div>{/* end module */}
              </div>{/* end col */}
              <div className="col-md-8">
                <div className="custom-module">
                  <h2>We make sure our clients are not just satisfied but we ensure that they are happy with their service and we gain a lot from it.</h2>
                  <div className="row">
                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 first">
                      <ul className="check">
                        <h3>Web</h3>
                        <li>Dynamic &amp; Static design</li>
                        <li>User Friendly Interface</li>
                        <li>Responsive Theme</li>
                        <li>Creative Web Layouts</li>
                        <li>Optimized for Perfomance</li>
                      </ul>{/* end check */}
                    </div>{/* end col-lg-4 */}
                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                      <ul className="check">
                        <h3>Graphics</h3>
                        <li>Engaging Artwork</li>
                        <li>Brand identity</li>
                        <li>Original Content</li>
                        <li>Motion Graphics</li>
                        <li>Art &amp; illustrations</li>
                      </ul>{/* end check */}    
                    </div>{/* end col-lg-4 */}
                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 last">
                      <ul className="check">
                        <h3>Marketing</h3>
                        <li>Content Creation</li>
                        <li>Social Media</li>
                        <li>SEO &amp; SEM</li>
                        <li>Email Marketing</li>
                        <li>Audience Targeting</li>
                      </ul>{/* end check */}
                    </div>{/* end col-lg-4 */} 
                  </div>{/* end row */}   
                </div>{/* end module */}
              </div>{/* end col */}
            </div>{/* end row */}
          </div>{/* end container */}
        </section>
      </div>

    
    </>
  )
}

export default About