import React from 'react'




function Header() {


  return (
    <>
      <section id="header" className="light-header">
        <header className="header">
          <div className="container">
      
            <nav className="navbar navbar-default yamm">
              <div className="navbar-header">
                <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar" aria-expanded="false" aria-controls="navbar">
                  <span className="sr-only">Toggle navigation</span>
                  <span className="icon-bar" />
                  <span className="icon-bar" />
                  <span className="icon-bar" />
                </button>
                <div className="logo-normal">
                  <div className="navbar-brand"><img src="images/logo.svg" width="300px" heigh="144px" alt="logo" /></div>
                </div>
              </div>
              <div id="navbar" className="navbar-collapse collapse">
                <ul className="nav navbar-nav navbar-right">
                  <li><a href="/">Home</a></li>
                  <li><a href ="/About">About</a></li>
                  <li><a href ="/Services">Services</a></li>
                  <li><a href ="/Blog">Blog</a></li>
                  <li><a href ="/Contact">Contact</a></li>
                  <li><a href="/knowledge">Knowledge</a></li>
                </ul>
              </div>{/*/.nav-collapse */}
            </nav>
          </div>
        </header>{/* /header */}
      </section>
    
 

</>
  )
}

export default Header