import React from 'react'

function BlogWhyFoot() {

  return (
    <>
     <section className="section">
          <div className="container">
          <div class="section-title text-center">
                    <h3>More Articles</h3>
                    <p>If you’re interested in digital marketing, social media, network marketing, marketing strategy, email marketing, content marketing, market segmentation, then we invite you to explore by reading more articles here.</p>
                </div>
            <div className="row text-center">
              <div className="col-lg-4 col-md-12">
                <div className="blog-box">
                  <div className="post-media">
                    <a href="Social Media Marketing What It Is, How It Works, Pros And Cons"><img src="images/blog/Social-Media-Marketing-What-It-Is_-How-It-Works_-Pros-And-Cons.webp" width="100%" height="100%" alt="Social Media Marketing What It Is, How It Works, Pros And Cons" className="img-responsive" /></a>
                  </div>{/* end media */}
                  <div className="blog-desc">
                    <h4><a href="Social Media Marketing What It Is, How It Works, Pros And Cons">Social Media Marketing: What It Is, How It Works, Pros And Cons</a></h4>
                    <p>Do you want to become a social media marketing expert? Do you have a business that's in need of more exposure and web traffic? Learn the basics of this digital marketing technique, including what it is, how it works, and the pros and cons of using it.</p>
                  </div>{/* end blog-desc */}
                  <div className="post-meta">
                    <ul className="list-inline">
                      <li>12 Nov 2022</li>
                      <li>Business</li>
                      <li>Social Media</li>
                      <li>Digital Marketing</li>
                    </ul>
                  </div>{/* end post-meta */}
                </div>{/* end blog */}
              </div>{/* end col */}
              <div className="col-lg-4 col-md-12">
                <div className="blog-box">
                  <div className="post-media">
                    <a href="Should You Choose Public Relations Or Content Marketing For Your Business"><img src="images/blog/social-media.webp" width="100%" height="100%" alt="Why Your Business Should Not Be On Every Social Media Platform" className="img-responsive" /></a>
                  </div>{/* end media */}
                  <div className="blog-desc">
                    <h4><a href="Should You Choose Public Relations Or Content Marketing For Your Business">Should You Choose Public Relations Or Content Marketing For Your Business</a></h4>
                    <p>The world is evolving with every passing second. The same methods brands used to grow their presence a few years back may not work. In the past, brands have relied heavily on public relations to create a strong foundation for their businesses.</p>
                  </div>{/* end blog-desc */}
                  <div className="post-meta">
                    <ul className="list-inline">
                      <li>13 Nov 2022</li>
                      <li>Business</li>
                      <li>Social Media</li>
                      <li>Digital Marketing</li>
                    </ul>
                  </div>{/* end post-meta */}
                </div>{/* end blog */}
              </div>{/* end col */}
              <div className="col-lg-4 col-md-12">
                <div className="blog-box">
                  <div className="post-media">
                    <a href="2023 Digital Marketing Trends to Take Advantage of Now"><img src="images/blog/2023-Digital-Marketing-Trends-to-Take-Advantage-of-Now.webp" width="100%" height="100%" alt="2023 Digital Marketing Trends to Take Advantage of Now" className="img-responsive" /></a>
                  </div>{/* end media */}
                  <div className="blog-desc">
                    <h4><a href="2023 Digital Marketing Trends to Take Advantage of Now">2023 Digital Marketing Trends to Take Advantage of Now</a></h4>
                    <p>Tools to utilize the power of digital marketing are faster than ever, and social media has brought the world closer together than ever before. In this article, we'll be highlighting some of the top trends we're expecting to see in the digital marketing scene by 2023. </p>
                  </div>{/* end blog-desc */}
                  <div className="post-meta">
                    <ul className="list-inline">
                      <li>14 Nov 2022</li>
                      <li>Business</li>
                      <li>Social Media</li>
                      <li>Digital Marketing</li>
                    </ul>
                  </div>{/* end post-meta */}
                </div>{/* end blog */}
              </div>{/* end col */}
            </div>{/* end row */}
          </div>
        </section> 
        </>
  )
}

export default BlogWhyFoot