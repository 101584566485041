import  { useState, useEffect } from 'react';
import axios from 'axios';
import { useGeolocated } from 'react-geolocated';
import {GiWindsock} from 'react-icons/gi'
import { WiThermometer, WiHumidity, WiDaySunny, WiCloudy, WiRain, WiSnowWind, WiThunderstorm, WiFog } from 'react-icons/wi'

const WeatherIcon = ({ iconCode }) => {
  // Define icons corresponding to different weather conditions
  const iconMap = {
    '01d': <WiDaySunny size={40} />,
    '01n': <WiDaySunny size={40}  />,
    '02d': <WiCloudy size={40} />,
    '02n': <WiCloudy size={40} />,
    '03d': <WiCloudy size={40} />,
    '03n': <WiCloudy size={40} />,
    '04d': <WiCloudy size={40} />,
    '04n': <WiCloudy size={40} />,
    '09d': <WiRain size={40} />,
    '09n': <WiRain size={40} />,
    '10d': <WiRain size={40} />,
    '10n': <WiRain size={40} />,
    '11d': <WiThunderstorm size={40} />,
    '11n': <WiThunderstorm size={40} />,
    '13d': <WiSnowWind size={40} />,
    '13n': <WiSnowWind size={40} />,
    '50d': <WiFog size={40}/>,
    '50n': <WiFog size={40}  />,
    
  };

  // Return the corresponding icon based on the icon code
  return iconMap[iconCode] || null;
};




function Weather() {
  const [currentLocation, setCurrentLocation] = useState(null);
  const [weatherCondition, setWeather] = useState(null);
  const {
    coords,
    isGeolocationAvailable,
    isGeolocationEnabled,
    positionError,
  } = useGeolocated();

  useEffect(() => {
    if (!isGeolocationAvailable || !isGeolocationEnabled || positionError) {
      return; // Return early if geolocation isn't available or enabled, or if there's an error
    }

    if (!coords) {
      return; // Return early if coordinates are not available yet
    }

    const getLocation = async () => {
     
      try {
        const fetchWeather = await axios.get(
          `${process.env.REACT_APP_ORIGIN}/api/weather?lat=${coords.latitude}&lon=${coords.longitude}`
        );
        setWeather(fetchWeather.data);
        setCurrentLocation(fetchWeather.data);
      } catch (error) {
        console.error('Error fetching weather:', error);
      }
    }

    getLocation();
  }, [coords, isGeolocationAvailable, isGeolocationEnabled, positionError]);

// convert temp to celsius and round to 1 decimal
const temp = weatherCondition?.main?.temp - 273.15
const tempCelsius = temp.toFixed(0)
const windspeed = weatherCondition?.wind?.speed
const humidity = weatherCondition?.main?.humidity
const tempIcon = <WiThermometer size={40} />
const windIcon = <GiWindsock size={40} />
const humidityIcon = <WiHumidity size={40} />




var icon = weatherCondition?.weather[0]?.icon



return (
  
  <div className="weather">
    {weatherCondition && (
    // {weatherCondition?.weather[0]?.description && <h4>{weatherCondition?.weather[0]?.description}</h4>}
    <table>
    <tbody>
      <tr>
        <td>
        <WeatherIcon iconCode={icon} />
        {/* <img
          src={url}
          name="weatherIcon"
          alt="weatherIcon"
          /> */}
        </td>
        <td>{currentLocation.name}</td>
      </tr>
      <tr>

        <td>{tempIcon}</td>
        <td>{tempCelsius}°C</td>
      </tr>
      <tr>
        <td>{windIcon}</td>
        <td>{windspeed} m/s</td>
      </tr>
      <tr>
        <td>{humidityIcon}</td>
        <td>{humidity}%</td>
      </tr>
  
      </tbody>
    </table>
    )}
  </div>
)

}

export default Weather

