import React from "react";
import axios from "axios";
import { useState } from "react";
import Spinner from "./Spinner";
import { ToastContainer, toast } from "react-toastify";

function Newsletter() {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [spinner, setSpinner] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    try {
      if (email === "") {
        setError("Please enter your email");
        return;
      } else {
        setError("");
      }
      setSpinner(true);
      axios
        .post(`${ process.env.REACT_APP_ORIGIN}/api/newsletter`, {
          email: email,
        })
        .then((res) => {
          setSpinner(false);
          toast.success("Subscribed successfully");

          setEmail("");
        })
        .catch((err) => {
          if (err.response.status === 400) {
            toast.error(err.response.data);

            setSpinner(false);
          }

          // no internet connection
          else if (err.response.status === 404) {
            toast.error("Check your internet connection");
            setSpinner(false);
          }

          // server error
          else if (err.response.status === 500) {
            toast.error("Try again later");
            setSpinner(false);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  return (
    <>
      <form onSubmit={handleSubmit} id="formNews">
        <div id="errorEmail">{error}</div>

        <div className="u-s-m-b-15">
          <input
            type="email"
            className="news-l__input validate-input"
            name="Email"
            id="email"
            value={email}
            onChange={handleChange}
            data-validate
            placeholder="E-mail Address"
          />
          <div className="u-s-m-b-15">
            <br />
            <input
              type="submit"
              name="submit"
              className="btn2 btn-primary"
              id="submit"
              onClick={handleSubmit}
              defaultValue="Subscribe"
            />
            <div id="loader" style={{ textAlign: "center" }}>
              <br />
              {spinner && <Spinner />}
            </div>
            <div className="u-s-m-b-15">
              <br />
              <p className="bp__p">
                By Signing up, you agree to receive enigmAnce offers, promotions
                and other commercial messages. You may unsubscribe at any time.
              </p>
            </div>
          </div>
          <div id="error">
            <img src="images/error.gif" alt="gfgg" />
            <label id="errorText">Request Failed. Try Again</label>
          </div>
        </div>
        <div className="u-s-m-b-15 new-l--center"></div>
        <ToastContainer
          position="top-center"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
        />
      </form>
    </>
  );
}

export default Newsletter;
