
import { Helmet } from 'react-helmet'

const Blog = () => {

  return (
    <>
      <div>
         <Helmet>
          <meta charSet="utf-8" />
          <meta http-equiv="X-UA-Compatible" content="IE=edge"/>
          <title>Blog</title>
          <link rel="alternate" hreflang="en-KE" 
          href="https://enigmAnce.com/blog"/>
          <link rel="alternate" hreflang="x-default" href="https://enigmAnce.com/blog"/>
          <meta name="language" content="English"/>
          <meta name="keywords" content="Graphic Design, Animation, Video Marketing, Motion Graphic, Logo Animation, Banner, Youtube, Social Media, facebook, instagram, pinterest, tik tok"/>
          <meta name="description" content="Stay well-informed and on top of your game with our latest articles..."/>
          <meta name="author" content="enigmAnce"/>
          <link rel="alternate" href="https://enigmAnce.com/blog" hreflang="en-ke" />
        </Helmet>
    </div>
 
    
        <section className="section lb">
          <div className="container">
            <div className="section-title text-center">
              <h1>Latest Articles</h1>
              <p className="blogP">Stay well-informed and be at the top of your game with our latest articles. Whether you're a business or a marketing professional, this section contains knowledge that can help you grow your business and improve your return of investment.</p>
            </div>{/* end title */}
            <div className="row">
              <div className="col-lg-3 col-md-4 col-sm-12">
                <h3>Categories</h3><br /><br />
                <ul className="categories">
                  <li><a href="https://enigmance.com/Social%20Media%20Marketing%20What%20It%20Is,%20How%20It%20Works,%20Pros%20And%20Cons">Social Media</a></li>
                  <li><a href="https://enigmance.com/Why%20Your%20Business%20Should%20Not%20Be%20On%20Every%20Social%20Media%20Platform">Marketing Online</a></li>
                  <li><a href="https://enigmance.com/2023%20Digital%20Marketing%20Trends%20to%20Take%20Advantage%20of%20Now">Digital Trends</a></li>
                </ul>
                <h4>Quick Links</h4>
                <button className="btn--e-transparent-brand-b-2"><a href="https://enigmance.com/Social%20Media%20Marketing%20What%20It%20Is,%20How%20It%20Works,%20Pros%20And%20Cons">Social Media</a></button>
                <button className="btn--e-transparent-brand-b-2"><a href="https://enigmance.com/Social%20Media%20Marketing%20What%20It%20Is,%20How%20It%20Works,%20Pros%20And%20Cons">Online Marketing</a></button>
                <button className="btn--e-transparent-brand-b-2"><a href="https://enigmance.com/Why%20Your%20Business%20Should%20Not%20Be%20On%20Every%20Social%20Media%20Platform">Digital Marketing</a></button><button className="btn--e-transparent-brand-b-2"><a href="https://enigmance.com/2023%20Digital%20Marketing%20Trends%20to%20Take%20Advantage%20of%20Now">Brand Marketing</a></button>
                <br />
                <button className="btn--e-transparent-brand-b-2"><a href="https://enigmance.com/Should%20You%20Choose%20Public%20Relations%20Or%20Content%20Marketing%20For%20Your%20Business">Content Marketing</a></button>
                <button className="btn--e-transparent-brand-b-2"><a href="https://enigmance.com/Should%20You%20Choose%20Public%20Relations%20Or%20Content%20Marketing%20For%20Your%20Business">Brand Marketing</a></button>
                <br />
                <br />
                <br />
                {/* end col */}
                <h3>Useful Reads</h3>
                <div className="readmore">
                  <p className="bp2">14th Dec 2022</p>
                  <br />
                  <span className="SideBarLinks">
                    <a href="https://enigmance.com/Knowledge/Reasons%20Why%20Your%20Small%20Business%20Needs%20an%20Online%20Presence" target="_blank" rel="noreferrer">Reasons Why Your Small Business Needs a Website</a>
                  </span>
                </div>
                <p className="bp__p">Giving your small business an online presence means more than simply putting up a little website with your company's address and phone number. It means setting up a virtual version of your business, with a welcoming, informative website, a Facebook page and Twitter account.</p>
                <div className="readmore">
                  <p className="bp2">14th Dec 2022</p>
                  <span className="SideBarLinks">
                    <a href="https://enigmance.com/Knowledge/Which%20Graphic%20Design%20Software%20is%20Best%20Suited%20For%20You" target="_blank" rel="noreferrer">Which Graphic Design Software is Best Suited For You in 2023</a>
                  </span>
                </div>
                <p className="bp__p">The best graphic design software gives artists powerful tools for creating professional designs that really stand out.</p> 
                <div className="readmore">
                  <p className="bp2">14th Dec 2022</p>
                  <span className="SideBarLinks">
                    <a href="https://enigmance.com/Knowledge/Difference%20Between%20Marketing%20and%20Advertisement" target="_blank" rel="noreferrer">So What's the Difference Between Marketing and Advertising ?</a>
                  </span>
                </div>
                <p className="bp__p">Marketing is an essential part of any business- and is closely related to advertising. However, they are not the same thing. Before starting a business, many people think advertising is marketing. But advertising is a form of marketing that targets consumers directly.</p> 
              </div>{/* end col */}
              <div className="col-lg-9 col-md-8 col-sm-12">
                <div className="knowledgeBox">
                  <div className="post-media">
                    <a href="https://enigmance.com/Social%20Media%20Marketing%20What%20It%20Is,%20How%20It%20Works,%20Pros%20And%20Cons"><img src="images/blog/Social-Media-Marketing-What-It-Is_-How-It-Works_-Pros-And-Cons.webp" width="1366px" height="766px" alt="Graphic Designing Agency" className="img-responsive" /></a>
                  </div>{/* end media */}
                  {/* end post-meta */}
                  <h2>Social Media Marketing: What It Is, How It Works, Pros And Cons</h2>
                  <div className="post-meta">
                    <button className="btn--e-transparent-brand-b-2"><a href="https://enigmance.com/Social%20Media%20Marketing%20What%20It%20Is,%20How%20It%20Works,%20Pros%20And%20Cons">Social Media</a></button>
                    <button className="btn--e-transparent-brand-b-2"><a href="https://enigmance.com/Social%20Media%20Marketing%20What%20It%20Is,%20How%20It%20Works,%20Pros%20And%20Cons">Online Marketing</a></button>
                  </div> 
                  <p className="bp__p">Do you want to become a social media marketing expert? Do you have a business that's in need of more exposure and web traffic? Learn the basics of this digital marketing technique, including what it is, how it works, and the pros and cons of using it.</p>
                  <div className="readmore">
                    <span className="bp__read-more">
                      <a href="https://enigmance.com/Social%20Media%20Marketing%20What%20It%20Is,%20How%20It%20Works,%20Pros%20And%20Cons">READ MORE</a>
                    </span>
                  </div>
                </div>
                <div className="knowledgeBox">
                  <div className="post-media">
                    <a href="https://enigmance.com/Why%20Your%20Business%20Should%20Not%20Be%20On%20Every%20Social%20Media%20Platform"><img src="images/blog/Why-Your-Business-Should-Not-Be-On-Every-Social-Media-Platform.webp" width="1366px" height="766px" alt="Web Designing and Development, digital marketing services" className="img-responsive" /></a>
                  </div>{/* end media */}
                  <h2>Why Your Business Should Not Be On Every Social Media Platform</h2>
                  <div className="post-meta">
                    <button className="btn--e-transparent-brand-b-2"><a href="https://enigmance.com/Why%20Your%20Business%20Should%20Not%20Be%20On%20Every%20Social%20Media%20Platform">Social Media</a></button>
                    <button className="btn--e-transparent-brand-b-2"><a href="https://enigmance.com/Why%20Your%20Business%20Should%20Not%20Be%20On%20Every%20Social%20Media%20Platform">Digital Marketing</a></button>
                  </div> 
                  <p className="bp__p">The ultimate idea behind a business being on every social media platform is that you can have your cake and eat-it too, but this is not an option for everyone.</p>
                  <div className="readmore">
                    <span className="bp__read-more">
                      <a href="https://enigmance.com/Why%20Your%20Business%20Should%20Not%20Be%20On%20Every%20Social%20Media%20Platform">READ MORE</a>
                    </span>
                  </div>
                </div>
                <div className="knowledgeBox">
                  <div className="post-media">
                    <a href="https://enigmance.com/2023%20Digital%20Marketing%20Trends%20to%20Take%20Advantage%20of%20Now"><img src="images/blog/2023-Digital-Marketing-Trends-to-Take-Advantage-of-Now.webp" width="1366px" height="766px" alt="Digital Marketing, Online Marketing, Social Media Marketing Agency, marketing agencies, online marketing platforms" className="img-responsive" /></a>
                  </div>{/* end media */}
                  <h2>2023 Digital Marketing Trends to Take Advantage of</h2>
                  <div className="post-meta">
                    <button className="btn--e-transparent-brand-b-2"><a href="https://enigmance.com/2023%20Digital%20Marketing%20Trends%20to%20Take%20Advantage%20of%20Now">Social Media</a></button>
                    <button className="btn--e-transparent-brand-b-2"><a href="https://enigmance.com/2023%20Digital%20Marketing%20Trends%20to%20Take%20Advantage%20of%20Now">Online Marketing</a></button>
                    <button className="btn--e-transparent-brand-b-2"><a href="https://enigmance.com/2023%20Digital%20Marketing%20Trends%20to%20Take%20Advantage%20of%20Now">Brand Marketing</a></button>
                  </div> 
                  <p className="bp__p">Tools to utilize the power of digital marketing are faster than ever, and social media has brought the world closer together than ever before. In this article, we'll be highlighting some of the top trends we're expecting to see in the digital marketing scene by 2023.</p>
                  <div className="readmore">
                    <span className="bp__read-more">
                      <a href="https://enigmance.com/2023%20Digital%20Marketing%20Trends%20to%20Take%20Advantage%20of%20Now">READ MORE</a>
                    </span>
                  </div>
                </div>
                <div className="knowledgeBox">
                  <div className="post-media">
                    <a href="https://enigmAnce.com/blog"><img src="images/blog/social-media.webp" width="1366px" height="766px" alt="Digital Marketing, Online Marketing, Social Media Marketing Agency" className="img-responsive" /></a>
                  </div>{/* end media */}
                  <h2>Should You Choose Public Relations Or Content Marketing For Your Business</h2>
                  <div className="post-meta">
                    <button className="btn--e-transparent-brand-b-2"><a href="https://enigmance.com/Should%20You%20Choose%20Public%20Relations%20Or%20Content%20Marketing%20For%20Your%20Business">Content Marketing</a></button>
                    <button className="btn--e-transparent-brand-b-2"><a href="https://enigmance.com/Should%20You%20Choose%20Public%20Relations%20Or%20Content%20Marketing%20For%20Your%20Business">Brand Marketing</a></button>
                  </div> 
                  <p className="bp__p">The world is evolving with every passing second. The same methods brands used to grow their presence a few years back may not work. In the past, brands have relied heavily on public relations to create a strong foundation for their businesses. However, with the advent of social media, brands have had to move to digital strategies to create a positive image and boost brand awareness.</p>
                  <div className="readmore">
                    <span className="bp__read-more">
                      <a href="https://enigmance.com/Should%20You%20Choose%20Public%20Relations%20Or%20Content%20Marketing%20For%20Your%20Business">READ MORE</a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
   
    </>
  )
}

export default Blog