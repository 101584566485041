import React from 'react'
import ContacForm from './ContactForm'

function Contact() {
  return (
    <>

      <div>
        
        <section className="innerpage_top_section">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h3>Contacts</h3>
              </div>
            </div>
          </div>
        </section>
        <section className="section lb">
          <div className="container">
            <div className="section-title text-center">
              <h1>Use the Form below for queries or to just say hi 😍</h1>
            </div>{/* end title */}
            {/* end row */}  <div className="contact-form">
              <div className="flex items-center min-h-screen bg-gray-100 dark:bg-gray-900">
                <div className="container mx-auto">
                  <div className="max-w-xl mx-auto my-10 bg-white p-5 rounded-md shadow-sm">
                    <div className="m-7">

                     <ContacForm />

                     
                    </div>
                  </div>
                </div>
              </div>   
            </div>
          </div>{/* end container */}
        </section>
        <section className="section lb">
          <div className="container">
            <div className="row text-center">
              <div className="col-md-12">
              </div>
            </div>{/* end row */}
          </div>
        </section>
      </div>

    </>
    )
}

export default Contact