import React, { Component } from 'react';
import * as THREE from 'three';

class ThreeScene extends Component {
    componentDidMount() {
        // set the scene size
        const WIDTH = window.innerWidth,
        HEIGHT = window.innerHeight;
    
        // set some camera attributes
        const VIEW_ANGLE = 45,
        ASPECT = WIDTH / HEIGHT,
        NEAR = 0.1,
        FAR = 10000;
    
        // get the DOM element to attach to
        // - assume we've got jQuery to hand
        const $container = document.querySelector('#container');
    
        // create a WebGL renderer, camera
        // and a scene
        const renderer = new THREE.WebGLRenderer();
        const camera =
        new THREE.PerspectiveCamera(
            VIEW_ANGLE,
            ASPECT,
            NEAR,
            FAR
        );
    
        const scene = new THREE.Scene();
    
        // the camera starts at 0,0,0
        // so pull it back
        camera.position.z = 300;
    
        // start the renderer
        renderer.setSize(WIDTH, HEIGHT);

        // color the background transparent
        renderer.setClearColor( 0x000000, 0 ); // the default

    
        // attach the render-supplied DOM element
        $container.appendChild(renderer.domElement);
    
        // create the sphere's material
        const sphereMaterial =
        new THREE.MeshLambertMaterial(
            {
            color: 0xCC0000         
            });
    
        // set up the sphere vars
        const RADIUS = 50,
        SEGMENTS = 16,
        RINGS = 16;
    
        // create a new mesh with
        // sphere geometry - we will cover
        // the sphereMaterial next!
        const sphere = new THREE.Mesh(
    
        new THREE.SphereGeometry(
            RADIUS,
            SEGMENTS,
            RINGS),
    
        sphereMaterial);
    
        // add the sphere to the scene
        scene.add(sphere);
    
        // create a point light
        const pointLight =
        new THREE.PointLight(0xFFFFFF); // white light

        //animataion
        const animate = function () {
            requestAnimationFrame( animate );
            sphere.rotation.x += 0.01;
            sphere.rotation.y += 0.01;
            renderer.render( scene, camera );
        };
        animate();

        const onResize = () => {
            camera.aspect = window.innerWidth / window.innerHeight;
            camera.updateProjectionMatrix();
            renderer.setSize(window.innerWidth, window.innerHeight);
        };
        window.addEventListener('resize', onResize, false);

    
        // set its position
        pointLight.position.x = 10;
        pointLight.position.y = 50;
        pointLight.position.z = 130;
    
        // add to the scene
        scene.add(pointLight);
    
        // draw!
        renderer.render(scene, camera);
    }
    
    render() {
        return (
            <div id="container"  />
        );
    }
    }




export default ThreeScene;
