import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import Router from './routes';
import Header from './components/Header';
import Footer from './components/Footer';



export default function App() {
  return (
    <HelmetProvider>
      <BrowserRouter>
        <Header />
        <Router />
        <Footer />
      </BrowserRouter>
    </HelmetProvider>
    
  );
}
