import React from 'react'
import { ColorRing } from 'react-loader-spinner'



function Spinner() {
  return (
    <div id="ajaxSpinnerContainer">
      <ColorRing
      visible={true}
      height="50"
      width="50"
      ariaLabel="blocks-loading"
      wrapperStyle={{transform: 'translate(-50%, -50%)'}}
      wrapperClass="blocks-wrapper"
      colors={['#ff2d2d', '#ff2d2d', '#ff2d2d', '#ff2d2d', '#ff2d2d']}
      text-align="center"
/>

    </div>
  )
}

export default Spinner