import { Helmet, HelmetProvider } from 'react-helmet-async';
import Newsletter from './Newsletter'

const Home = () => {
  return (
    <>
    <HelmetProvider>
    <div>
      <Helmet>
  <meta charSet="utf-8" />
  <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
  <meta name="viewport" content="width=device-width" />
  <title>enigmAnce - Digital Marketing Immortalized</title>
  <link rel="canonical" href="https://enigmAnce.com" />
  <link rel="alternate" hrefLang="en-KE" href="https://enigmAnce.com" />
  <link rel="alternate" hrefLang="x-default" href="https://enigmAnce.com" />
  <meta
    name="keywords"
    content="digital marketing, social media, network marketing, marketing strategy, email marketing, content marketing, market segmentation, marketing management, marketing services, graphic, design, brand management, web design, business "
  />
  <meta
    name="description"
    content="We offer a range of services to help with all aspects of your online presence. From high-quality web development to graphic design, social media & marketing"
  />
  <meta name="robots" content="index, follow" />
  <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
  <meta name="language" content="English" />
  <link rel="shortcut icon" href="images/favicon.ico" type="image/x-icon" />

    
  <meta property="fb:pages" content={105481065704779} />
  <meta property="fb:app_id" content={838406994270743} />
  <meta property="og:url" content="https://enigmAnce.com" />
  <meta property="og:type" content="website" />
  <meta
    property="og:title"
    content="enigmAnce - Digital Content Immortalized"
  />
  <meta
    property="og:description"
    content="We offer a range of services to help with all aspects of your online presence. From high-quality web development to graphic design, social media & marketing"
  />
  <meta
    property="og:image"
    content="https://enigmAnce.com/images/upload/slider_05.webp"
  />
  <meta name="twitter:card" content="summary_large_image" />
  <meta property="twitter:site" content="@enigmAncestudio" />
  <meta property="twitter:url" content="https://enigmAnce.com" />
  <meta
    name="twitter:title"
    content="enigmAnce -Digital Content Immortalized"
  />
  <meta
    name="twitter:description"
    content="We offer a range of services to help with all aspects of your online presence. From high-quality web development to graphic design, social media & marketing"
  />
  <meta
    name="twitter:image"
    content="https://enigmAnce.com/images/upload/slider_05.webp"
  />
 </Helmet>  
 </div>
 </HelmetProvider>

    
    <div>
        <article className="content">
          <img src="images/upload/slider_05.webp" width="1920px" height="1080px" alt="Graphic Designing Agency" className="img-responsive" />
        </article>{/* end media */}
        <section className="section">
          <div className="container">
            <div className="row">
              <div className="col-md-4 col-sm-6">
                <div className="custom-module">
                  <h1>We are enigmAnce</h1>
                  <p className="blogP">Dedicated at delivering high quality digital content for your business. We specialize in web development, marketing strategies and graphic design to help you create a brand that stands out. We produce industry-leading content for websites, printed media, marketing campaigns or anything your brand or company needs done in order to be successful.</p><br />
                  <div className="btn-wrapper">
                    <a href="services" className="btn btn-primary">Know More</a>
                  </div>
                </div>{/* end custom-module */}
              </div>{/* end col */}
              <div className="col-md-4 col-sm-6">
                <div className="custom-module">
                  <br /> <h2>What to Expect</h2>
                  <ul className="list-style-2">
                    <li><i className="fa fa-star-o" /> Only Pay for What You Get, No Minimums.</li>
                    <li><i className="fa fa-star-o" /> Cost Effective &amp; Speedy Service</li>
                    <li><i className="fa fa-star-o" /> No Upfront Cost</li>
                    <li><i className="fa fa-star-o" /> Customized Content Marketing</li>
                    <li><i className="fa fa-star-o" /> Customer Service Excellence</li>
                    <li><i className="fa fa-star-o" /> 100% Satisfaction Guaranteed!</li>
                  </ul>
                </div>{/* end custom-module */}
              </div>{/* end col */}
              <div className="col-md-4 col-sm-6">
                <div className="custom-module">
                  <br /><h2>Our Services</h2>
                  <ul className="list-style-2">
                    <li><i className="fa fa-star-o" /> Powerful &amp; Creative Web Design Layouts</li>
                    <li><i className="fa fa-star-o" /> Eye Catching Visuals</li>
                    <li><i className="fa fa-star-o" /> Engaging &amp; Fresh Content</li>
                    <li><i className="fa fa-star-o" /> Brand Awareness &amp; Digital Campaigns</li>
                    <li><i className="fa fa-star-o" /> Online Marketing &amp; Social Media</li>
                    <li><i className="fa fa-star-o" /> Audience Targeting and Fine Tuning</li>
                  </ul>
                </div>{/* end custom-module */}
              </div>{/* end col */}
            </div>{/* end row */}
          </div>{/* end container */}
        </section>
        <section className="section lb">
          <div className="container">
            <div className="section-title text-center">
              <h3>Why enigmAnce</h3>
            </div>
            <div className="section-title">
              <p className="BlogP">Our strength lie in the ability to deliver products and services that are more than just visually appealing but also designed for perfomance and optimized for your particular needs. Every great idea starts with a conversation and every enigmAnce project begins with a discovery of your story. From graphic design to web developing, every project is thoughtfully crafted to promote user engagement and maximize results. We will help you reach the next milestone in your business evolution.<br />What are you waiting for.</p>
              {/* end title */}
            </div>
            <div className="col-md-4 col-sm-4">
              <div className="custom-module">
                <h2>Just What Your Business Needs</h2>
                <h3><mark>Step up Your Game</mark></h3>
                <p>Look no Further coz we got you! Our team of experts will provide you with the expertise and knowledge you need to make your online business a success. From creating a new Website, or marketing your brand in Social Media, or developing effective marketing campaigns for your existing website, we're here to take your business to the next level!</p>
                <div className="btn-wrapper">
                  <a href="services" className="btn btn-primary">Get it Done</a>
                </div>
              </div>{/* end custom-module */}
            </div>{/* end col */}
            <div className="col-md-4 col-sm-4">
              <div className="custom-module">
                <h2>Get it Fixed <mark>Today</mark></h2>
                <h3>Find your Solution with us!</h3>
                <ul className="list-style-2">
                  <li><i className="fa fa-star-o" /> Customized Trouble Shooting</li>
                  <li><i className="fa fa-star-o" /> Measurable Perfmomance</li>
                  <li><i className="fa fa-star-o" /> Enhanced Look &amp; Feel</li>
                  <li><i className="fa fa-star-o" /> Engaging Content</li>
                  <li><i className="fa fa-star-o" /> Brand Exposure</li>
                  <li><i className="fa fa-star-o" /> Perfomance Optimization</li>
                  <li><i className="fa fa-star-o" /> Marketing Strategy</li>
                  <li><i className="fa fa-star-o" /> Audience Re-targeting</li>
                </ul>
              </div>{/* end custom-module */}
            </div>{/* end col */}
            <div className="col-md-4 col-sm-4">
              <div className="custom-module">
                <h2><mark>Get Started Now!</mark></h2>
                <h3>Feel &amp; See The Difference</h3>
                <ul className="check">
                  <li>Increased Conversion</li>
                  <li>Increased Web Traffic</li>
                  <li>Quality Leads</li>
                  <li>Extensive Reach</li>
                  <li>Customer Engagement</li>
                  <li>Search Engine Optimized</li>
                  <li>Boosted Brand Reach</li>
                  <li>Audience Growth</li>
                  <li>Engaged Customers</li>
                </ul>
                {/* end skills */}
              </div>{/* end custom-module */}
            </div>
          </div>{/* end container */}
        </section>
        <section className="section lb">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-12">
                <div className="service-box service-style-2">
                  <i className="flaticon-monitor" />
                  <h3>Powerful Design</h3>
                  <img src="images/illustration/web-developer.svg" width="100%" height="100%" alt="content creation, digital marketing companies " className="img-responsive" />
                  <p className="BlogP">Our design process is rapid and our approach daring,  but we are always up for a challenge. Rather than going with the typical cookie-cutter style of most sites, we design to be innovative and original while still fitting with the industry standard.</p>
                </div>
              </div>{/* end col */}
              <div className="col-lg-4 col-md-12">
                <div className="service-box service-style-2">
                  <i className="flaticon-technology" />
                  <h3>Optimized for Perfomance</h3>
                  <img src="images/illustration/perfomance.svg" width="100%" height="100%" alt="SEO optimization, marketing agencies" className="img-responsive" />
                  <p className="BlogP">Fast perfoming websites are the life blood of online businesses and at enigmAnce we pride ourselves in being able to provide a website that is fast &amp; secure. </p>
                </div>
              </div>{/* end col */}
              <div className="col-lg-4 col-md-12">
                <div className="service-box service-style-2">
                  <i className="flaticon-gears" />
                  <h3>User Friendly</h3>
                  <img src="images/illustration/easy.svg" width="100%" height="100%" alt="User Friendly websites, digital marketing companies " className="img-responsive" />
                  <p className="BlogP">All of our sites are designed to be simple and intuitive for your visitors and staff, but we don't stop there! We want to make sure that you have an enjoyable design experience too, with outstanding customer service every step of the way.</p>
                </div>
              </div>{/* end col */}
            </div>{/* end row */}
            <hr className="invis1" />
            <div className="row">
              <div className="col-lg-6 col-md-12">
                <div className="service-box service-style-2">
                  <i className="flaticon-computer" />
                  <h3>Grow Your Brand</h3>
                  <img src="images/illustration/socialmedia-marketing.svg" width="100%" height="100%" alt="brand marketing, social media in marketing" className="img-responsive" />
                  <p className="BlogP">At enigmAnce we make it personal. We know how to optimize your brand and drive growth. From viral content to viral marketing, we have all the tools you need to take your brand from one in five people to one in three, and beyond.</p>
                </div>
              </div>{/* end col */}
              <div className="col-lg-6 col-md-12">
                <div className="service-box service-style-2">
                  <i className="flaticon-people" />
                  <h3>Rank in Search</h3>
                  <img src="images/illustration/seo.svg" alt="Search Ranking" width="100%" height="100%" className="img-responsive" />
                  <p className="BlogP"> Get more visitors, rank in search and get found on the internet. At enigmAnce we utilize the latest marketing tools to get you seen, heard and understood . We do this by giving you the tools that give you the best chance to succeed from start.</p>
                </div>
              </div>{/* end col */}
            </div>{/* end row */}
          </div>{/* end container */}
        </section>
        <section className="section">
          <div className="container">
            <div className="row">
              <div className="col-md-4 hidden-sm hidden-xs">
                <div className="custom-module">
                  <img src="images/upload/device_01.webp" alt="social media in marketing, advertising agencies" width="579px" height="906px" className="img-responsive" />
                </div>{/* end module */}
              </div>{/* end col */}
              <div className="col-md-8">
                <div className="custom-module">
                  <h2>Focused on <mark>enriching</mark> your life by bringing you the best in all things cool.<br />
                    From web design, graphics, and creative online marketing<br />
                    Brilliance got better... it got <mark>enigmAnce</mark></h2>
                  <p className="BlogP">We are obsessive about bringing you the coolest quality products at the most affordable and making sure everything you buy has a story.</p>
                  <div className="row">
                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 first">
                      <h3>Web</h3>
                      <ul className="check">
                        <li>Dynamic &amp; Static design</li>
                        <li>User Friendly Interface</li>
                        <li>Responsive Theme</li>
                        <li>Creative Web Layouts</li>
                        <li>Optimized for Perfomance &amp; Security</li>
                      </ul>{/* end check */}
                    </div>{/* end col-lg-4 */}
                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                      <h3>Graphics</h3>
                      <ul className="check">
                        <li>Engaging Artwork</li>
                        <li>Brand identity</li>
                        <li>Original Design</li>
                        <li>Motion Graphics</li>
                        <li>Art &amp; illustrations</li>
                      </ul>{/* end check */}    
                    </div>{/* end col-lg-4 */}
                    <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12 last">
                      <h3>Marketing</h3>
                      <ul className="check">
                        <li>Content Creation</li>
                        <li>Social Media</li>
                        <li>SEO &amp; SEM</li>
                        <li>Email Marketing</li>
                        <li>Audience Targeting</li>
                      </ul>{/* end check */}
                    </div>
                    {/* end col-lg-4 */} 
                  </div>{/* end row */}   
                </div>{/* end module */}
              </div>{/* end col */}
            </div>{/* end row */}
          </div>{/* end container */}
        </section>
        <div className="title text-center">
          <strong><h3>Tired of Reading the boring text!!</h3></strong>
          <p className="BlogP">Then the below video will give you a better <br />understanding of our services.</p>
        </div>
        <section className="video-section">
          <div className="row">
            <div className="text-center">
              <video controls loop width="80%" height="60%" muted={true} preload="metadata" src="videos/enigmance.mp4" />
            </div>
          </div>
        </section>
        <br />
        <br />
        <br />
      
        <section className="section">
          <div className="container">
            <div className="section-title text-center">
              <h3>Latest Articles</h3>
              <p>Stay well-informed and on top of your game with our latest articles.</p>
            </div>{/* end title */}
            <div className="row text-center">
              <div className="col-lg-4 col-md-12">
                <div className="blog-box">
                  <div className="post-media">
                    <a href="Social Media Marketing What It Is, How It Works, Pros And Cons"><img src="images/blog/Social-Media-Marketing-What-It-Is_-How-It-Works_-Pros-And-Cons.webp" width="100%" height="100%" alt="Social Media Marketing What It Is, How It Works, Pros And Cons" className="img-responsive" /></a>
                  </div>{/* end media */}
                  <div className="blog-desc">
                    <h4><a href="Social Media Marketing What It Is, How It Works, Pros And Cons">Social Media Marketing: What It Is, How It Works, Pros And Cons</a></h4>
                    <p>Do you want to become a social media marketing expert? Do you have a business that's in need of more exposure and web traffic? Learn the basics of this digital marketing technique, including what it is, how it works, and the pros and cons of using it.</p>
                  </div>{/* end blog-desc */}
                  <div className="post-meta">
                    <ul className="list-inline">
                      <li>12 Nov 2022</li>
                      <li>Business</li>
                      <li>Social Media</li>
                      <li>Digital Marketing</li>
                    </ul>
                  </div>{/* end post-meta */}
                </div>{/* end blog */}
              </div>{/* end col */}
              <div className="col-lg-4 col-md-12">
                <div className="blog-box">
                  <div className="post-media">
                    <a href="Why Your Business Should Not Be On Every Social Media Platform"><img src="images/blog/Why-Your-Business-Should-Not-Be-On-Every-Social-Media-Platform.webp" width="100%" height="100%" alt="Why Your Business Should Not Be On Every Social Media Platform" className="img-responsive" /></a>
                  </div>{/* end media */}
                  <div className="blog-desc">
                    <h4><a href="Why Your Business Should Not Be On Every Social Media Platform">Why Your Business Should Not Be On Every Social Media Platform</a></h4>
                    <p>The ultimate idea behind a business being on every social media platform is that you can have your cake and eat-it too, but this is not an option for everyone. </p>
                  </div>{/* end blog-desc */}
                  <div className="post-meta">
                    <ul className="list-inline">
                      <li>13 Nov 2022</li>
                      <li>Business</li>
                      <li>Social Media</li>
                      <li>Digital Marketing</li>
                    </ul>
                  </div>{/* end post-meta */}
                </div>{/* end blog */}
              </div>{/* end col */}
              <div className="col-lg-4 col-md-12">
                <div className="blog-box">
                  <div className="post-media">
                    <a href="2023 Digital Marketing Trends to Take Advantage of Now"><img src="images/blog/2023-Digital-Marketing-Trends-to-Take-Advantage-of-Now.webp" width="100%" height="100%" alt="2023 Digital Marketing Trends to Take Advantage of Now" className="img-responsive" /></a>
                  </div>{/* end media */}
                  <div className="blog-desc">
                    <h4><a href="2023 Digital Marketing Trends to Take Advantage of Now">2023 Digital Marketing Trends to Take Advantage of Now</a></h4>
                    <p>Tools to utilize the power of digital marketing are faster than ever, and social media has brought the world closer together than ever before. In this article, we'll be highlighting some of the top trends we're expecting to see in the digital marketing scene by 2023. </p>
                  </div>{/* end blog-desc */}
                  <div className="post-meta">
                    <ul className="list-inline">
                      <li>14 Nov 2022</li>
                      <li>Business</li>
                      <li>Social Media</li>
                      <li>Digital Marketing</li>
                    </ul>
                  </div>{/* end post-meta */}
                </div>{/* end blog */}
              </div>{/* end col */}
            </div>{/* end row */}
          </div>
        </section> 
        {/* About Author Modal */}


      <div className="modal fade new-l" id="newsletter-modal">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content modal--shadow">
            <i className="new-l__dismiss fa fa-window-close" data-dismiss="modal" />
            <div className="modal-body">
              <div className="row u-s-m-x-0">
                <div className="col-lg-6 new-l__col-1 u-s-p-x-0">
                  <img className="img-responsive" src="images/illustration/newsletter-01.svg" alt="News" width="760px" height="800px" /></div>
                <div className="col-lg-6 new-l__col-2">
                  <div className="new-l__section u-s-m-t-30">
                    <div className="u-s-m-b-8 new-l--center">
                      <h3 className="h2">Newsletter</h3>
                    </div>
                    <div className="u-s-m-b-30 new-l--center">
                      <p className="bp__p">Sign up to our newsletter &amp; get a scoop of new articles, special offers and more. 
                      </p>
                    </div>
                    <div className="u-s-m-b-15 new-l--center">
                    </div>
                    {/* ,jdfnsnd.m;dv;adm.m;E:FMLSC */}
                    <Newsletter />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  
      </div>


    </>
  )
}

export default Home

